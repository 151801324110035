<template>
  <v-app id="app" app>
    <header-component />
    <v-main app>
      <v-container class="px-2">
        <slot>
        </slot>
      </v-container>
    </v-main>
    <footer-component />
  </v-app>
</template>

<script>
import FooterComponent from '@/components/main-components/footer'
import HeaderComponent from '@/components/main-components/header'
export default {
  name: 'Default',
  components: { FooterComponent, HeaderComponent },
}
</script>
<style lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Source+Code+Pro:wght@300;400&display=swap');
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
body{
  background-color: var(--v-background-base) !important;
}
#app {
  font-family: Roboto, sans-serif;

  .v-tabs > .v-tabs-bar{
    background: transparent !important;
  }

  .title{
    white-space: pre-wrap;
    word-break:break-word;
  }


  .glass-block {
    margin: 20px 0;
    padding: 0 15px;
    background: var(--v-primary-base) !important;
    border: 1px solid rgba(255, 255, 255, 0.08);
    box-sizing: border-box;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.05);
    backdrop-filter: blur(4px);
    border-radius: 8px;
  }
  .custom-table {
    background: transparent !important;
    ::v-deep .v-data-table-header {

      th {
        border-top: 1px solid rgba(255,255,255, 0.3);
        border-bottom: 1px solid rgba(255,255,255, 0.3) !important;
        font-size: 13px !important;
        line-height: 16px !important;
      }
    }

    tbody tr :hover {
      cursor: pointer;
    }



  }

  .sites{
    display: inline-block;
    max-height: 200px !important;
    overflow: auto;
  }
  .v-list {
    padding: 0 !important;
  }

  .theme--dark.v-expansion-panels .v-expansion-panel {
    background: rgba(255, 255, 255, 0.1) !important;
  }

  .theme--light.v-expansion-panels .v-expansion-panel {
    background: rgba(250, 250, 250, 1) !important;
  }

  .item {
    padding: 8px 0;
    margin: 0 0 0 -10px;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    border-radius: 8px;
    transition: .2s linear;
    cursor: pointer;

    &__icon {
      margin: 0 10px;
      padding: 20px;
      background: rgba(255, 255, 255, 0.08);
      border-radius: 8px;
    }

    &__action {
      display: flex;
    }

    &__details {
      padding-right: 5px;
      flex-grow: 1;
    }
    &:hover {
      transition: .2s linear !important;
      background-color: rgba(255, 255, 255, .1) !important;
    }

    &:hover .item .item__icon svg path{
      fill-rule: nonzero !important;
    }
  }
  .v-list {
    padding: 0 !important;
  }



  .code-text {
    font-family: 'Source Code Pro', monospace;
  }

  .table {
    &__data-table {
      ::v-deep .v-data-table-header {
        border-color: rgba(255, 255, 255, 1) !important;
        background: #141A42 !important;

        th {
          border-top: 1px solid rgba(255,255,255, 0.3);
          border-bottom: 1px solid rgba(255,255,255, 0.3) !important;
          font-size: 13px !important;
          line-height: 16px !important;
          color: #FCFCFD !important;
        }
      }
    }
  }

  .theme--dark.table__data-table {
    background: transparent !important;
  }

  .theme--light.table__data-table {
    background: #ffffff !important;
  }

  .theme--dark {
    .navigation {
      &__item {
        display: block;
        padding: 17px;
        transition: .2s linear;
        cursor: pointer;
        a {
          text-decoration: none;
        }
        &:hover {
          background: rgba(255,255,255, .3);
          transition: .2s linear;
        }
      }

      &__item-active {
        display: block;
        padding: 17px;
        transition: .2s linear;
        cursor: pointer;
        background-color: rgba(57, 85, 167, 1);

        &--light {
          display: block;
          padding: 17px;
          transition: .2s linear;
          cursor: pointer;
          background-color: #96ffff;
        }
      }
    }
  }

  .theme--light {
    .navigation {
      &__item {
        display: block;
        padding: 17px;
        transition: .2s linear;
        cursor: pointer;
        a {
          text-decoration: none;
        }
        &:hover {
          background: rgba(0,0,0, .1);
          transition: .2s linear;
        }
      }

      &__item-active {
        display: block;
        padding: 17px;
        transition: .2s linear;
        cursor: pointer;
        background-color: #96ffff;
      }
    }
  }

  .theme--dark.content-card {
    background: rgba(255, 255, 255, 0.1) !important;
  }
  .theme--light.content-card {
    background: rgba(255, 255, 255,1) !important;
  }

  .theme--dark.v-tab {
    text-transform: capitalize;
    color: rgba(255, 255, 255, 0.87) !important;
  }

  .v-tabs-items{
    background: transparent !important;
  }

.transbg{
  background: transparent !important;
}

  .link {
    color: white;
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }
  .theme--light.v-tab {
    text-transform: capitalize;
    color: rgba(0, 0, 0, 0.87) !important;
  }

  .footer-logo{
    -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
    filter: grayscale(100%);
  }

  .theme--dark.v-card {
    background: #2C3249;
  }
}
.breadcrumbs-item a {
  color: #2DBAE6;
}

.theme--dark.v-application{
  background-image: url('../assets/images/BG Variants.png') !important;
}

.v-application {
  background-color: var(--v-background-base) !important;
  background-position: left center;
  background-size: contain;
}

.theme--light.v-application a {
  color: rgba(0, 0, 0, 0.87) !important;
}

.theme--dark.v-application a {
  color: rgba(255, 255, 255, 0.87) !important;
}


.theme--light.v-application .primary--text {
  color: rgba(0, 0, 0, 0.87) !important;
}

.theme--dark.v-application .primary--text {
  color: rgba(255, 255, 255, 0.87) !important;
}



</style>