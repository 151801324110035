<template>
  <div>
    <v-navigation-drawer v-if="accountID && smallScreen" color="primary" v-model="sidebar" app>
      <v-list  >
        <v-list-item class="px-2 justify-center">
          <v-list-item-avatar>
            <v-img src="../../assets/images/img.png"></v-img>
          </v-list-item-avatar>
        </v-list-item>

        <v-list-item link>
          <v-list-item-content>
            <v-list-item-title class="text-h6">
              LivePerson Status Page
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>

      <v-divider></v-divider>
      <v-list dense nav>
        <v-list-item
            link
            :to="`/dashboard/${accountID}`"
        >
          <v-list-item-icon>
            <v-icon>mdi-view-dashboard</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Dashboard</v-list-item-title>
          </v-list-item-content>

        </v-list-item>

        <v-list-item
            link
            :to="`/current/${accountID}`"
        >
          <v-list-item-icon>
            <v-icon>mdi-list-status</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Current Status</v-list-item-title>
          </v-list-item-content>

        </v-list-item>

        <v-list-item
            link
            :to="`/history/${accountID}`"
        >
          <v-list-item-icon>
            <v-icon>mdi-history</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>History</v-list-item-title>
          </v-list-item-content>

        </v-list-item>

        <v-list-item
            link
            :to="`/futuremaintenance/${accountID}`"
        >
          <v-list-item-icon>
            <v-icon>mdi-clock-outline</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Maintenance</v-list-item-title>
          </v-list-item-content>

        </v-list-item>

      </v-list>
    </v-navigation-drawer>
    <v-app-bar  color="primary" app  absolute flat hide-overlay>
          <span v-if="accountID && smallScreen">
            <v-app-bar-nav-icon @click="sidebar = !sidebar"></v-app-bar-nav-icon>
          </span>


      <div class="d-flex justify-center align-center pa-3" v-if="isQA">
        <div class="item">
          <a href="./">
            <span class="notify-badge">QA</span>
            <v-img
                src="../../assets/images/LivePerson.png"
                contain
                max-width="200px"
                class="d-md-block d-none"
                @click="$router.push('/')"
            />
            <v-img
                src="../../assets/images/img.png"
                contain
                min-width="35px"
                max-width="50px"
                class="d-block d-md-none"
                @click="$router.push('/')"
            />
          </a>
        </div>
      </div>

      <template v-else>
      <v-img
          src="../../assets/images/LivePerson.png"
          contain
          max-width="200px"
          class="d-md-block d-none"
          @click="$router.push('/')"
      />
      <v-img
          src="../../assets/images/img.png"
          contain
          min-width="35px"
          max-width="50px"
          class="d-block d-md-none"
          @click="$router.push('/')"
      />
      </template>
      <v-spacer />

      <v-chip label outlined dense v-if="!smallScreen && accountID" class="pa-0" >
        <v-select
            style="max-width: 250px"
            class="pa-0"
            v-model="timezone"
            :items="timeZones"
            color="secondary"
            dense
            outlined
            hide-details
            item-color="white"
            :menu-props="{
            offsetY: true}"
        >
          <template #item="{on, attrs, item}">
            <v-list-item dense v-bind="attrs" color="secondary" class="primary my-0" v-on="on">
              {{ item }}
            </v-list-item>
          </template>
        </v-select>
      </v-chip>

      <v-btn icon @click="toggle_dark_mode">
        <v-icon>mdi-theme-light-dark</v-icon>
      </v-btn>
      <v-btn
          link
          :to="`${accountID ? `/api?site=${accountID}` : `/api`}`"
          target="_blank"
          text
          color="white"
          class="ml-3 px-1 d-md-block d-none"
      >
        <v-icon large>
          mdi-api
        </v-icon>
        <v-icon>mdi-arrow-top-right</v-icon>
      </v-btn>
      <template v-if="accountID && !smallScreen" v-slot:extension >
        <div class="menu" >
          <v-btn-toggle>
            <v-btn
                class="primary"
                link
                text
                :to="`/dashboard/${accountID}`">
              <v-icon left dark>mdi-view-dashboard</v-icon>
              Dashboard
            </v-btn>

            <v-btn
                class="primary"
                link
                text
                :to="`/current/${accountID}`">
              <v-badge
                  :content="currentStatus.length"
                  :value="currentStatus.length"
                  color="red"
              >
                <v-icon left dark>mdi-list-status</v-icon>
                Current Status
              </v-badge>

            </v-btn>

            <v-btn
                class="primary"
                link
                text
                :to="`/history/${accountID}`">
              <v-icon left dark>mdi-history</v-icon>
              History
            </v-btn>
            <v-btn
                class="primary"
                link
                text
                :to="`/futuremaintenance/${accountID}`">
              <v-badge
                  :content="futureMaintenance.length"
                  :value="futureMaintenance.length"
                  color="red"
              >
                <v-icon left dark>mdi-clock-outline</v-icon>
                Future Maintenance
              </v-badge>

            </v-btn>
          </v-btn-toggle>
        </div>
        <v-chip label outlined>Account Number: {{ accountID }}</v-chip>
        <v-chip label outlined>Farm: {{ lob }}</v-chip>
        <v-spacer></v-spacer>
        <v-chip label outlined>{{ date }}</v-chip>

      </template>
    </v-app-bar>
  </div>
</template>

<script>
import {  mapGetters } from 'vuex'

export default {
  name: 'HeaderComponent',
  data: () => ({
    timeZones: [],
    sidebar: false,
    accountMenu: false,
    date: null
  }),
  created () {
    this.timeZones = this.$constants.timeZones
    setInterval(() => {
      this.date = this.$moment.tz(new Date(), this.timezone).format('DD-MMMM-YYYY hh:mm:ss A')
    }, 1000)
  },
  computed: {
    ...mapGetters('Site', ['getTimeZone', 'getLob', 'getName', 'getRealTimeIncidents', 'getOngoingMaintenance', 'getFutureMaintenance']),
    currentStatus(){
      return [...this.getOngoingMaintenance, ...this.getRealTimeIncidents]
    },
    futureMaintenance(){
      return this.getFutureMaintenance
    },
    isQA(){
      return window.location.href.indexOf('localhost') !== -1 || window.location.href.indexOf('lpstatus') !== -1;
    },
    accountID () {
      return this.$route.params.site
    },
    lob(){
      return this.getLob
    },
    name(){
      return this.getName
    },
    smallScreen () {
      return ['md', 'xs', 'sm'].includes(this.$vuetify.breakpoint.name)
    },

    timezone: {
      get () {
        return this.getTimeZone;
      },
      set (newValue) {
        this.$store.dispatch('Site/updateTimezone', newValue);
      }
    }
  },
  methods:{
    toggle_dark_mode() {
      this.$vuetify.theme.dark = !this.$vuetify.theme.dark;
      localStorage.setItem("dark_theme", this.$vuetify.theme.dark.toString());
    },

  }

}
</script>

<style scoped lang="scss">
.item {
  position:relative;
  padding-top:20px;
  display:inline-block;
}
.notify-badge{
  position: absolute;
  right:-17px;
  top:5px;
  background:red;
  text-align: center;
  border-radius: 30px 30px 30px 30px;
  color:white;
  padding:3px 3px;
  font-size:10px;
}
.menu{
  left:0;
  right:0;
  margin-left: auto;
  margin-right: auto;
  position: absolute;
  width: 40%;
}
</style>
